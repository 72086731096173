<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="box box-default box-solid box-component">
          <div class="box-body">
            <div class="details-header--details">
              <h2>Dane osobowe</h2>
              <ul class="list-unstyled task-header--details">
                <li><label>Imię:</label> {{ user.forename }}</li>
                <li><label>Nazwisko:</label> {{ user.surname }}</li>
                <li><label>Numer telefonu:</label> {{ user.phone }}</li>
                <li><label>Adres e-mail:</label> {{ user.email }}</li>
              </ul>
            </div>
            <hr>
            <table class="user-role-edit-table table-light">
              <thead>
                <tr>
                  <th class="table-light--label ">
                    Klient
                  </th>
                  <th class="table-light--label">
                    Rola
                  </th>
                  <th class="table-light--desc">
                    Opis
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="app in apps"
                >
                  <template
                    v-if="rolesLoaded[app.name]"
                  >
                    <tr
                      v-for="(userRole, roleIndex) in roles[app.name]"
                      :key="`${app.name}_${roleIndex}`"
                      :class="{'major-tr': roleIndex === 0}"
                    >
                      <td
                        v-if="roleIndex === 0"
                        :rowspan="roles[app.name].length"
                      >
                        {{ app.label }}
                      </td>
                      <td>
                        <div class="user-role-edit-checkbox">
                          <input
                            :id="`${app.name}_${userRole.name}`"
                            v-model="rolesModel[app.name]"
                            type="checkbox"
                            :value="userRole.name"
                            @change="updateChanges($event, roleIndex)"
                          >
                          <label :for="`${app.name}_${userRole.name}`">{{ userRole.label }}{{ userRole.businessRole ? ' (rola biznesowa)' : '' }}</label>
                        </div>
                      </td>
                      <td>{{ userRole.description }}</td>
                    </tr>

                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../../../../../../api'
import DynamicBreadcrumb from '../../../../share/mixins/DynamicBreadcrumb'
import Page from '../../../../../share/content/Page'
import Webstorage from '../../../../../share/WebStorage'

export default {
  mixins: [
    DynamicBreadcrumb,
    Page
  ],
  data () {
    return {
      events: {
        submitData: 'core:userRole:submitData'
      },
      headerTitle: { title: 'Użytkownik', description: 'Edycja ról' },
      apps: [
      ],
      roles: {},
      userRoles: {},
      rolesLoaded: {},
      rolesModel: [],
      initialSelectedRoles: {
      },
      changes: {
      },
      saveDataInformationVisible: false,
      user: {}
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getApps()
      this.mountInitialSelectedRoles()
      this.mountChanges()
      this.loadUserData()
    })
    this.$events.on(this.events.submitData, this.onSubmitForm)
    this.$events.on('core:userRole:cancel', this.onCancel)
  },
  methods: {
    getApps () {
      this.apps = Object.entries(Webstorage.getContractorApps()).map((value, index) => {
        return {
          id: index,
          name: value[0],
          label: value[1]
        }
      })
    },
    mountInitialSelectedRoles () {
      let tmp = {}
      Object.entries(Webstorage.getContractorApps()).forEach(function (value) {
        tmp[value[0]] = {}
      })
      this.initialSelectedRoles = tmp
    },
    mountChanges () {
      let tmp = {}
      Object.entries(Webstorage.getContractorApps()).forEach(function (value) {
        tmp[value[0]] = 0
      })
      this.changes = tmp
    },
    loadContent () {
      this.toggleLoading()
      for (let c = 0; c < this.apps.length; c++) {
        this.userRoles[this.apps[c].name] = []
        this.rolesModel[this.apps[c].name] = []
      }
      this.rolesLoaded = this.initLoaded()
      for (let c = 0; c < this.apps.length; c++) {
        var appname1 = this.apps[c].name
        api.request(appname1, 'get', `/roles`)
          .then((response) => {
            let appName = this.apps[c].name
            this.roles[appName] = response.data
            this.initSelectedRoles(appName, response.data)
            api.request(appName, 'get', `/users/${this.user.uuid}/roles`)
              .then((response) => {
                let appName = this.apps[c].name
                let userRoles = response.data
                for (let d = 0; d < userRoles.length; d++) {
                  this.userRoles[appName].push(userRoles[d])
                  this.rolesModel[appName].push(userRoles[d].name)
                  this.initialSelectedRoles[appName][userRoles[d].name] = 1
                }
                this.rolesLoaded[appName] = true
              })
              .catch(() => {
                this.toggleLoading()
              })
          })
          .catch(() => {
            this.toggleLoading()
          })
      }
    },
    loadUserData () {
      api.request('core', 'get', `users/${this.$route.params.uuid}`)
        .then((response) => {
          this.toggleLoading()
          this.user = response.data.user

          // dynamic breadcrumb labels
          this.addDynamicBreadcrumbLabel('admin_user_details', this.user.forename + ' ' + this.user.surname)

          this.loadContent()
        })
        .catch(() => {
          this.toggleLoading()
        })
    },
    initLoaded () {
      let result = {}
      for (let c = 0; c < this.apps.length; c++) {
        result[this.apps[c].name] = false
      }
      return result
    },
    initSelectedRoles (appName, roles) {
      for (let role in roles) {
        this.initialSelectedRoles[appName][role] = 0
      }
    },
    updateChanges (event, index) {
      let id = event.target.id
      let appName = id.substr(0, id.indexOf('_'))

      if (this.initialSelectedRoles[appName][event.target.value] ^ event.target.checked) {
        this.changes[appName] |= 1 << index
      } else {
        this.changes[appName] &= ~(1 << index)
      }
    },
    onCancel (event) {
      for (let c = 0; c < this.apps.length; c++) {
        if (this.changes[this.apps[c].name] > 0) {
          this.saveDataInformationVisible = true
        }
      }

      if (!this.saveDataInformationVisible) {
        this.$events.$emit('dashboard:menu:redirect', 'admin_user_details')
      }
    },
    onSubmitForm () {
      this.toggleLoading()

      for (let c = 0; c < this.apps.length; c++) {
        let appName = this.apps[c].name
        api.request(appName, 'put', `/users/${this.$route.params.uuid}/roles`, this.rolesModel[appName])
          .then((response) => {
            this.toggleLoading()
            this.$router.push({ name: 'admin_user_details' })
          })
          .catch(() => {
            this.toggleLoading()
          })
      }
    }
  }
}
</script>
